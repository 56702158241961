<template>
    <div class="contact page-fade-in">
        <div class="banner">
        </div>
        <div class="inner-wrap">
            <h1 class="title">
                联系方式
            </h1>
            <div class="icons">
                <div class="icon">
                    <div class="image">
                        <img alt="" src="../assets/image/icon_tel.png">
                    </div>
                    <div class="text">
                        400-1866-389
                    </div>
                </div>
                <div class="icon">
                    <div class="image">
                        <img alt="" src="../assets/image/icon_email.png">
                    </div>
                    <div class="text">
                        dedia@licheng.com
                    </div>
                </div>
                <div class="icon">
                    <div class="image">
                        <img alt="" src="../assets/image/icon_address.png">
                    </div>
                    <div class="text">
                        福建省厦门市集美区
                        <br>
                        百通科技园2号楼301
                    </div>
                </div>
            </div>
            <baidu-map :center="{lng: 118.0503, lat: 24.6169}" :dragging="false" :scroll-wheel-zoom="false" :zoom="18"
                       id="map">
                <bm-marker :dragging="false" :position="{lng: 118.0503, lat: 24.6169}"/>
                <span>厦门利成智能科技有限公司</span>
            </baidu-map>
        </div>
    </div>
</template>

<script>

    export default {
        name: "Contact",
        mounted() {

        },
        created() {
            /* var map = new BMap.Map("map");
             console.log(map)
             var point = new BMap.Point(118.0503, 25.6169);
             var marker = new BMap.Marker(point);  // 创建标注
             map.addOverlay(marker);
             
             var opts = {
                 width: 250,     // 信息窗口宽度
                 height: 100,    // 信息窗口高度
                 title: "Hello"  // 信息窗口标题
             }
             var infoWindow = new BMap.InfoWindow("World", opts);  // 创建信息窗口对象
             // map.openInfoWindow(infoWindow, map.getCenter());        // 打开信息窗口
             marker.addEventListener("click", function(){
                 map.openInfoWindow(infoWindow, point); //开启信息窗口
             });*/
        }
    }

</script>

<style lang="scss" scoped>
    .contact {
        width: 100%;
        min-height: 100vh;
        background: #FFFFFF;
        
        .banner {
            background: url("../assets/image/contact_banner.png") center;
            background-size: cover;
            height: 700px;
        }
        
        .inner-wrap {
            width: 1200px;
            margin: 0 auto;
            padding-bottom: 100px;
            padding-top: 100px;
            
            .title {
                margin-top: 0;
                margin-bottom: 32px;
                font-size: 3rem;
                text-align: center;
            }
            
            #map {
                width: inherit;
                height: 400px;
                position: relative;
                margin-top: 60px;
                
                span {
                    position: absolute;
                    display: flex;
                    top: calc(45% - 55px);
                    left: 50%;
                    transform: translateX(-50%);
                    
                    height: 50px;
                    width: 220px;
                    background: #FFFFFF;
                    color: #666666;
                    border-radius: 8px;
                    box-shadow: 4px 4px 16px 0 rgba(55, 51, 51, 0.1);
                    
                    font-size: 16px;
                    justify-content: center;
                    align-items: center;
                }
            }
            
            .icons {
                display: flex;
                justify-content: space-between;
                
                .icon {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    align-items: center;
                    
                    .text {
                        font-size: 18px;
                        font-weight: 400;
                        color: #333333;
                        line-height: 28px;
                    }
                }
            }
        }
        
        .BMap_cpyCtrl {
            display: none;
        }
        
        .anchorBL {
            display: none;
        }
    }
    
    @media only screen and (max-width: 767px) {
        .contact {
            .banner {
                height: 350px;
                background-position: center;
            }
            
            .inner-wrap {
                width: 100%;
                padding-bottom: 24px;
                padding-top: 24px;
                
                .title {
                    font-size: 18px;
                    margin: 0 0 16px;
                }
                
                #map {
                    height: 200px;
                    margin-top: 12px;
                    padding: 0 16px;
                    box-sizing: border-box;
                    
                    span {
                        height: 40px;
                        font-size: 12px;
                        width: 170px;
                    }
                }
                
                .icons {
                    flex-direction: column;
                    
                    .icon {
                        margin-bottom: 16px;
                        
                        .text {
                            font-size: 14px;
                            line-height: 20px;
                        }
                        
                        .image {
                            img {
                                width: 36px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
